import React, { useState, useEffect } from "react";
import "@fortawesome/fontawesome-free/css/all.min.css";

export const Footer = () => {
	useEffect(() => {
		function svglength(elem) {
			this.pathLength = elem.getTotalLength();
		}
		var pathElem = document.getElementById("path");
		var svgpathinstance = new svglength(pathElem);
	}, []);

	return (
		<div className="footer">
			<div className="footer-copyright">
				<p className="light">Defralcoding © 2023</p>
			</div>
			<div className="footer-credits">
				<p className="light">
					Made with ❤️ by{" "}
					<a className="link" href="https://www.defralcoding.it">
						Defralcoding
						<svg viewBox="0 0 120.72 50.7">
							<path
								id="path"
								d="M9.1,45.19H111.62c4.58,0,22.88-54.1-50.34-43.91C-33.22,14.43-2.02,50.14,45.71,50.67c140.96,1.57,50.34-64.29-6.41-39.2"
							/>
						</svg>
					</a>
				</p>
			</div>
			<div className="footer-social">
				<a
					href="https://github.com/defralcoding/"
					target="_blank"
					rel="noopener noreferrer"
				>
					<i className="fab fa-github icon"></i>
				</a>
				<a
					href="https://twitter.com/aledefralcoding"
					target="_blank"
					rel="noopener noreferrer"
				>
					<i className="fab fa-twitter icon"></i>
				</a>
			</div>
		</div>
	);
};

import React, { useEffect, useRef } from "react";
import { gsap, ScrollTrigger, Draggable, MotionPathPlugin } from "gsap/all";
gsap.registerPlugin(ScrollTrigger, Draggable, MotionPathPlugin);
export default function Hero() {
  return (
    <div className="header">
    
      <h1 className="hero-text">DefralCoding</h1>
      <h2 className="hero-sub-text">Making #MultiversX a better place</h2>

      <div className="hero-contacts py-5">
      <a
          href="https://t.me/alessandrodef"
          target="_blank"
          rel="noopener noreferrer"
        >
          <i className="fa-brands fa-telegram"></i>
        </a>
        <a
          href="https://twitter.com/aledefralcoding"
          target="_blank"
          rel="noopener noreferrer"
        >
          <i className="fa-brands fa-twitter"></i>
        </a>
        <a
          href="https://github.com/defralcoding"
          target="_blank"
          rel="noopener noreferrer"
        >
          <i className="fa-brands fa-github"></i>
        </a>
        <a
          href="mailto:info@defralcoding.it"
          target="_blank"
          rel="noopener noreferrer"
        >
          <i className="fa-solid fa-envelope"></i>
        </a>
        
       
      </div>

      <i class="fa-solid fa-arrow-down"></i>
    </div>
  );
}

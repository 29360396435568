import React, { useContext, useEffect, useRef } from "react";
import { gsap, ScrollTrigger } from "gsap/all";
import AnimationContext from "../../contexts/animation-context";

export default function AboutUs() {
  let secondRef = useRef(null);
  let textRef = useRef(null);
  let link1Ref = useRef(null);
  let link2Ref = useRef(null);
  let animationContext = useContext(AnimationContext);
  gsap.registerPlugin(ScrollTrigger);

  useEffect(() => {
    gsap.timeline({
      scrollTrigger: {
        trigger: secondRef.current,
        start: "+=200 70%",
        end: "+=200 60%",
        scrub: true,
        markers: false,
        pinSpacing: false,
        onEnter: () => {
          animationContext.setCurrentBg("#0a0a0a");
          animationContext.setCurrentLogo("#fff");
          gsap.to(textRef.current, {
            color: "#fff",
            duration: 0.2,
          });
          gsap.to(link1Ref.current, {
            color: "#fff",
            duration: 0.1,
          });
          gsap.to(link2Ref.current, {
            color: "#fff",
            duration: 0.1,
          });
        },
        onLeaveBack: () => {
          animationContext.setCurrentBg("#f5f0ed");
          animationContext.setCurrentLogo("#000");
          gsap.to(textRef.current, {
            color: "#000",
            duration: 0.4,
          });
          gsap.to(link1Ref.current, {
            color: "#000",
            duration: 0.1,
          });
          gsap.to(link2Ref.current, {
            color: "#000",
            duration: 0.1,
          });
        },
      },
    });

    function svglength(elem) {
      this.pathLength = elem.getTotalLength();
    }
    var pathElem = document.getElementById("path");
  }, []);
  return (
    <div ref={secondRef} className="aboutus">
      <div className="container-aboutus">
        <p ref={textRef} className="p-aboutus">
          We are{" "}
          <a
            href="https://www.linkedin.com/in/alessandro-de-franceschi-47784119a/"
            className="underline-animation"
            target="_blank"
            rel="noopener noreferrer"
            ref={link2Ref}
          >
            Alessandro
          </a>{" "}
          and{" "}
          <a href="/" className="underline-animation" ref={link1Ref}>
            Alessio
          </a>
          , two dynamic and passionate developers specializing in smart
          contracts, decentralized applications and mobile applications. <br />
          Our focus is on utilizing the latest technology, particularly in the
          exciting world of Web3, to create intuitive, innovative and accessible
          digital solutions for everyone. <br />
          If you are looking for innovative and reliable developers to bring
          your digital ideas to life, you have come to the right place.
        </p>
      </div>
    </div>
  );
}

import {NavBar} from './components/NavBar';
import {Footer} from './components/Footer';
import 'bootstrap/dist/css/bootstrap.min.css';
import'./App.css';
import "animate.css/animate.min.css";
import React, { useEffect, useRef, useState } from 'react';
import { AnimationOnScroll } from 'react-animation-on-scroll';
import {gsap} from 'gsap/all'
import AnimationContext from './contexts/animation-context';

import Hero from './pages/Hero';
import AboutUs from './pages/AboutUs';
import Works from './pages/Works';
import {ReactComponent as DacLogo} from './assets/img/dac-header.svg';


function App() {
  const [currentBg, setCurrentBg] = useState('#f5f0ed')
  const [currentLogo, setCurrentLogo] = useState('#000')
  let appRef = useRef(null);
  let logoRef = useRef(null);
  let navRef = useRef(null);
  useEffect(() => {
    gsap.to(appRef.current, {
      duration: 0.5,
      background: currentBg,
    })
    gsap.to(navRef.current, {
      duration: 0.5,
      backgroundImage: `linear-gradient(${currentBg} 90%,transparent)`
    })
    gsap.to(logoRef.current, {
      duration: 0.5,
      fill: currentLogo,
    })
    

  }, [currentBg]);
  
  return (
  <>
    <AnimationContext.Provider value={{ setCurrentBg,setCurrentLogo }}
    >
      <div ref={appRef}>
        <div className="px-4 fixed-top" style={{paddingTop:24,paddingBottom:15}} ref={navRef}>
          <DacLogo ref={logoRef} height={55}/>
        </div>
        <Hero/>
        <AboutUs/>
        <Works/>
        <Footer/>
      </div>
    </AnimationContext.Provider>
    
  </>
  );
}

export default App;

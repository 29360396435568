import { Navbar, Container, Nav } from "react-bootstrap";
import React, { useState, useEffect } from "react";

export const WorkCard = (props) => {
  const name = props.name;
  const description = props.description;
  const img = props.img;
  const link = props.link;
  const categories = props.categories ? props.categories : [];

  return (
    <div
      className={`work-card ${props.isBlurred ? "blurred" : ""}`}
      onMouseEnter={props.onMouseEnter}
      onMouseLeave={props.onMouseLeave}
    >
      <a href={link} target="_blank">
        <img className="work-image" alt={name + " image"} src={img} />
      </a>
      <div className="work-text">
        <h3 className="work-title">{name}</h3>
        <p className="work-description">{description}</p>
      </div>

      <div className="work-categories">
        {categories.map((category, index) => (
          <span className="work-category" key={index}>
            {category}
          </span>
        ))}
      </div>
    </div>
  );
};

import React, { useContext, useEffect, useRef, useState } from "react";
import { gsap, ScrollTrigger } from "gsap/all";
import AnimationContext from "../../contexts/animation-context";
import { WorkCard } from "../../components/WorkCard";

import xObserverBanner from "../../assets/img/xObserver_banner.jpg";
import xBulkBanner from "../../assets/img/xBulk_banner.jpg";
import TiredClubDappBanner from "../../assets/img/tiredClubdApp_banner.png";
import stakingScBanner from "../../assets/img/stakingSc_banner.jpeg";
import StakingNotifierBanner from "../../assets/img/stakingNotifier_banner.png";
import EVWork from "../../assets/img/evwork.png";
import xKingWork from "../../assets/img/xkingwork.png";

export default function Works() {
  const [hoveredCardName, setHoveredCardName] = useState(null);
  let thirdRef = useRef(null);
  let textRef = useRef(null);
  let animationContext = useContext(AnimationContext);
  gsap.registerPlugin(ScrollTrigger);

  useEffect(() => {
    gsap.timeline({
      scrollTrigger: {
        trigger: thirdRef.current,
        start: "+=20 70%",
        end: "+=20 60%",
        scrub: true,
        markers: false,
        pinSpacing: false,
        onEnter: () => {
          animationContext.setCurrentBg("#0d0d0d");
          gsap.to(textRef.current, {
            color: "#fff",
            duration: 0.4,
          });
        },
        onLeaveBack: () => {
          animationContext.setCurrentBg("#0a0a0a");
          gsap.to(textRef.current, {
            color: "#fff",
            duration: 0.6,
          });
        },
      },
    });
  }, []);

  return (
    <div
      ref={thirdRef}
      className={`works ${hoveredCardName ? "blur-background" : ""}`}
    >
      <div className="container-works">
        <h1 className="p-works">Our Portfolio</h1>
        <div ref={textRef} className="works-cards">
          <WorkCard
            categories={["dApp", "Smart Contract"]}
            name="xBulk"
            description="The best collection of tools for making your life easier when dealing with lots of transactions on the MultiversX blockchain."
            img={xBulkBanner}
            link="https://xbulk.app"
            onMouseEnter={() => setHoveredCardName("xBulk")}
            onMouseLeave={() => setHoveredCardName(null)}
            isBlurred={hoveredCardName && hoveredCardName !== "xBulk"}
          />
          <WorkCard
            categories={["App Mobile", "Observer"]}
            name="xObserver"
            description="The MultiversX Blockchain's mobile explorer to get an instant, comprehensive overview of the ecosystem's Hypergrowth"
            img={xObserverBanner}
            link="https://linktr.ee/xObserver"
            onMouseEnter={() => setHoveredCardName("xObserver")}
            onMouseLeave={() => setHoveredCardName(null)}
            isBlurred={hoveredCardName && hoveredCardName !== "xObserver"}
          />
          <WorkCard
            categories={[
              "dApp",
              "Staking",
              "Smart Contract",
              "Lottery",
              "Token",
            ]}
            name="EV Staking"
            description="The 1st Event Planner Co. on #MultiversX, offering real-world utility! Stake your Cyborgs on our dApp for double rewards $EGLD & $UTX https."
            img={EVWork}
            link="https://dapp.eventivivi.it"
            onMouseEnter={() => setHoveredCardName("EV Staking")}
            onMouseLeave={() => setHoveredCardName(null)}
            isBlurred={hoveredCardName && hoveredCardName !== "EV Staking"}
          />
		  <WorkCard
            categories={[
              "dApp",
              "Staking",
              "Smart Contract",
              "NFT Evolution",
              "Token",
            ]}
            name="xKingNFTx"
            description="Reward Driven Project on #MultiversX '503 $EGLD distributed since January2023 to our community' ."
            img={xKingWork}
            link="https://dapp.xkingnftx.com/"
            onMouseEnter={() => setHoveredCardName("xKingNFTx")}
            onMouseLeave={() => setHoveredCardName(null)}
            isBlurred={hoveredCardName && hoveredCardName !== "xKingNFTx"}
          />
          <WorkCard
            categories={["dApp", "Staking", "Smart Contract", "DAO"]}
            name="Tired Club dApp"
            description="dApp for the Tired Club NFT project. The first community owned Staking Agency on MultiversX. The dApp allows users to stake their NFTs and earn rewards, participate in the DAO, and more."
            img={TiredClubDappBanner}
            link="https://dapp.tiredclub.art"
            onMouseEnter={() => setHoveredCardName("Tired Club dApp")}
            onMouseLeave={() => setHoveredCardName(null)}
            isBlurred={hoveredCardName && hoveredCardName !== "Tired Club dApp"}
          />
          <WorkCard
            categories={["Staking", "Smart Contract"]}
            name="Staking Smart Contracts"
            description="A collection of smart contracts developed for the MultiversX blockchain, including NFT staking, token staking, and token staking with locking period contracts."
            img={stakingScBanner}
            link="https://github.com/defralcoding/staking-sc"
            onMouseEnter={() => setHoveredCardName("Staking Smart Contracts")}
            onMouseLeave={() => setHoveredCardName(null)}
            isBlurred={
              hoveredCardName && hoveredCardName !== "Staking Smart Contracts"
            }
          />
          <WorkCard
            categories={["Telegram Bot"]}
            name="Telegram Staking Notifier Bot"
            description="A specialized Telegram bot developed for Staking Agencies. The bot provides real-time notifications about free space, tracking of stake additions, and an end-of-epoch review. Currently implemented in the groups of Tortuga Staking, MG Staking, and Ofero Staking."
            img={StakingNotifierBanner}
            link="https://t.me/ElrondStakingNotifier_bot"
            onMouseEnter={() =>
              setHoveredCardName("Telegram Staking Notifier Bot")
            }
            onMouseLeave={() => setHoveredCardName(null)}
            isBlurred={
              hoveredCardName &&
              hoveredCardName !== "Telegram Staking Notifier Bot"
            }
          />
        </div>
      </div>
    </div>
  );
}
